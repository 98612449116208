/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => (
  <header
    css={css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      margin: 0 0 3em;
      text-align: center;
    `}
  >
    <div
      css={css`
        background: #ff8200;
        box-sizing: border-box;
        display: inline-block;
        padding: 1em 3em;
        width: 20em;
        max-width: 90%;
      `}
    >
      <h1
        css={css`
          font-weight: inherit;
          margin: 0;
          font-size: 200%;
        `}
      >
        <Link
          to="/"
          css={css`
            color: inherit;
            text-decoration: none;
            line-height: 1;
          `}
        >
          {(
            <div>
              <span
                css={css`
                  font-size: 40%;
                `}
              >
                Azienda Agricola
              </span>
              <br />
              <span>La Ginestra</span>
            </div>
          ) || siteTitle}
        </Link>
      </h1>
      <p
        css={css`
          margin-bottom: 0.2em;
        `}
      >
        Coming soon.
      </p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
