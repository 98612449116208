/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { Fragment } from "react"
/** @jsx jsx */
import { Global, css, jsx } from "@emotion/core"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Fragment>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Gabriela&display=swap");
          html,
          body {
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
          html {
            font-family: "Gabriela", serif;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          body {
            margin: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer
        css={css`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;
          margin: 3em 0 0;
          text-align: center;
        `}
      >
        <div
          css={css`
            background: #ff8200;
            box-sizing: border-box;
            display: inline-block;
            padding: 1.4em 3ex 2em;
            width: 20em;
            max-width: 90%;

            @media (min-width: 70em) {
              padding-bottom: 1em;
            }

            & a {
              color: inherit;
              text-decoration: underline;
              display: inline-block;
              margin: 0.3em;
            }
          `}
        >
          <p
            css={css`
              margin: 0;
              font-size: 80%;
            `}
          >
            Podere La Ginestra, 48
            <br />
            58045 Monte Antico GR Italia
            <br />
            <br />
            <a href="tel:00393484800938‬">Tel. +39 348 4800938‬</a>
            <br />
            <a href="https://www.instagram.com/azaglaginestra">
              Instagram
            </a>{" "}
            <a href="https://www.facebook.com/laginestra.gp">Facebook</a>
          </p>
        </div>
      </footer>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
